<template>
  <div>
    <QueryForm
      v-model="queryForm"
      :query-btn-loading="tableLoading"
      :show-export="true"
      @handleExport="handleExport"
      @query="handleQuery"
      @reset="handleQueryFormReset"
    >
      <el-form-item label="赔偿单号">
        <el-input
          v-model="queryForm.compensationNo"
        />
      </el-form-item>
      <el-form-item label="供应商">
        <Select
          v-model="queryForm.vendorIdList"
          api-key="vendorList"
          clearable
          multiple
        />
      </el-form-item>
      <el-form-item label="赔偿类型">
        <Select
          v-model="queryForm.compensationTypeDict"
          :select-options="_getAllCommodityDict('COMPENSATION_TYPE')"
          :configuration="optionsConfig"
          clearable
        />
      </el-form-item>
      <el-form-item label="状态">
        <Select
          v-model="queryForm.stateList"
          :select-options="_getAllCommodityDict('COMPENSATION_STATE')"
          :configuration="optionsConfig"
          clearable
          multiple
        />
      </el-form-item>
      <el-form-item label="业务类型">
        <Select
          v-model="queryForm.businessTypeDict"
          :select-options="_getAllCommodityDict('COMPENSATION_BUSINESS_TYPE')"
          :configuration="optionsConfig"
          clearable
        />
      </el-form-item>
      <el-form-item label="赔偿原因">
        <Select
          v-model="queryForm.reasonDictList"
          :select-options="reasonList"
          :configuration="optionsConfig"
          clearable
          multiple
        />
      </el-form-item>
      <el-form-item label="币种">
        <Select
          v-model="queryForm.currencyCodeList"
          :select-options="_getAllCommodityDict('CURRENCY_TYPE')"
          :configuration="optionsConfig"
          clearable
          multiple
        />
      </el-form-item>
      <el-form-item label="创建人">
        <Select
          v-model="queryForm.createByName"
          api-key="newUserList"
          clearable
        />
      </el-form-item>
      <el-form-item label="创建时间" :class="$i18n.locale">
        <el-date-picker
          v-model="queryForm.value1"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="选择日期"
          :default-time="['00:00:00','23:59:59']"
          type="datetimerange"
        />
      </el-form-item>
    </QueryForm>
    <el-row :gutter="10" class="mt-3 mb-3">
      <el-button v-permission="'add'" type="primary" @click="handleAdd('add')">新建</el-button>
      <el-button v-permission="'edit'" type="primary" @click="handleAdd('edit')">修改</el-button>
      <el-button v-permission="'invalid'" type="primary" :disabled="!multipleSelectionTable.length" @click="handleDelete">作废</el-button>

      <el-button v-permission="'confirm'" class="confirm" type="primary" @click="handleComponent">品质赔偿单确认
        <span v-if="amount" class="amount">{{ amount }}</span></el-button>

    </el-row>
    <Table
      :table-data="tableData"
      :max-height="'600px'"
      :columns="vendorComponentColumns"
      :page-obj="pager"
      :check="true"
      :loading="tableLoading"
      @handleSelectionChange="val=>multipleSelectionTable = val"
    >
      <el-table-column slot="payNo" label="赔偿单号" align="center" width="160">
        <template slot-scope="scope">
          <el-button type="text" :loading="scope.row.poNoLoading" @click="handleDetail(scope.row.id)">{{ scope.row.compensationNo }}</el-button>
        </template>
      </el-table-column>
    </Table>

    <Paging :id="id" :pager="pager" end @pagination="pagerUpdate" />
    <Add :id="id" v-model="dialogVisible" :type="type" @update="handleQuery" />
    <Confirm v-model="confirmDialogVisible" @update="handleQuery" />
    <el-dialog
      title="作废"
      :visible.sync="invalidDialogVisible"
      width="400px"
      center
    >
      <span>该操作将作废当前单据，请确认！</span>
      <el-form ref="validForm" :model="validForm" label-width="80px" class="mt-3">
        <el-form-item label="作废原因" prop="stateReason" :rules="{required: true, message: '必填',trigger:'blur'}">
          <el-input
            v-model="validForm.stateReason"
            type="textarea"
            :rows="2"
            :maxlength="200"
            show-word-limit
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="invalidDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteSure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import QueryForm from '@/components/QueryForm'
import Select from '@/components/Selection'
import Paging from '@/components/Pagination'
import Table from '@/components/Table'
import Add from './components/Add'
import Confirm from './components/Confirm'
import { handleDownload } from '@/utils/index'
import { vendorComponentColumns } from '@/constant/tablecolumns'
import { page, invalid, exportfile, countQms } from '@/api/composition'
import { omit } from 'lodash'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
export default {
  components: {
    QueryForm,
    Select,
    Paging,
    Table,
    Add, Confirm
  },
  mixins: [commodityInfoDict],
  data() {
    return {
      amount: 0,
      confirmDialogVisible: false,
      invalidDialogVisible: false,
      id: 0,
      type: '',
      dialogVisible: false,
      optionsConfig: { key: 'val', label: 'label', value: 'val' },
      tableLoading: false,
      vendorComponentColumns,
      tableData: [],
      multipleSelectionTable: [],
      queryForm: {},
      validDialog: false,
      pager: {
        current: 1,
        size: 20,
        total: 0
      },
      validForm: { stateReason: '' }
    }
  },
  computed: {
    queryParams() {
      const { value1 } = this.queryForm
      const [startCreateTime, endCreateTime] = value1 || []
      return Object.assign({},
        omit(this.queryForm, ['value1']),
        this.pager, { startCreateTime, endCreateTime }
      )
    },
    invalidParams() {
      const idList = []
      this.multipleSelectionTable.map(item => idList.push(item.id))
      return Object.assign({ idList }, this.validForm)
    },
    exportParams() {
      const obj = omit(this.queryParams, ['current', 'size', 'pages', 'total'])
      const idList = []
      if (this.multipleSelectionTable.length) {
        this.multipleSelectionTable.map(item => idList.push(item.id))
        Object.assign(obj, { idList: idList.join(',') })
      }
      return obj
    },
    reasonList() {
      const arrList = []
      const compensationTypeDict = this._getAllCommodityDict('COMPENSATION_TYPE')
      const businessTypeList = this._getAllCommodityDict('COMPENSATION_BUSINESS_TYPE')
      compensationTypeDict.map(item => businessTypeList.map(e => {
        const arr = this._getAllCommodityDict(`${item.val}_${e.val}`)
        arr.length && arrList.push(...arr)
      }))
      return arrList
    }
  },
  watch: {
    invalidDialogVisible(val) {
      if (val) {
         this.$refs.validForm?.clearValidate()
        this.$refs.validForm?.resetFields()
      }
    }
  },
  created() {

  },
  async mounted() {
    this.handleQuery()
  },
  methods: {
    handleComponent() {
      this.confirmDialogVisible = true
    },
    async handleExport() {
      const res = await exportfile(this.exportParams)
      handleDownload(res, '供应商赔偿单' + '.xlsx')
    },
    handleDetail(id) {
      this.id = id
      this.type = 'detail'
      this.dialogVisible = true
    },
    deleteSure() {
      this.$refs.validForm.validate(async valid => {
        if (valid) {
          const { code, msg } = await invalid(this.invalidParams)
          if (code === 0) {
            this.$notify({
              message: msg,
              type: 'success'
            })
            this.invalidDialogVisible = false
            this.handleQuery()
          }
        }
      })
    },
    handleDelete() {
      // 审批拒绝、草稿
      const flag = this.multipleSelectionTable.every(item => ['1', '4'].includes(item.state))
      if (!flag) return this.$message.warning('可选中多笔数据进行操作，且仅审批拒绝、草稿状态可操作作废')
      this.invalidDialogVisible = true
    },
    handleAdd(type) {
      this.type = type
      if (type === 'edit') {
        if (this.multipleSelectionTable.length !== 1 || !['1', '4'].includes(this.multipleSelectionTable[0].state)) {
          return this.$message.warning('仅审批拒绝、草稿状态可操作修改,且仅可选中单笔数据进行操作')
        }
        this.id = this.multipleSelectionTable[0].id
      }
      this.dialogVisible = true
    },

    pagerUpdate(val) {
      this.pager = val
      this._queryLists()
    },
    handleQuery() {
      this.pager.current = 1
      this._queryLists()
    },
    async queryCountQms() {
      const { datas } = await countQms()
      this.amount = datas
    },
    async _queryLists() {
      try {
        this.tableLoading = true
        const { code, datas: { pager: { total }, records = [] }} = await page(this.queryParams)
        if (code === 0) {
          this.tableData = records
          this.pager.total = total
        }
      } finally {
        this.tableLoading = false
        this.queryCountQms()
      }
    },
    handleQueryFormReset() {
      this.queryForm = this.$options.data().queryForm
      this.handleQuery()
    }
  }
}
</script>

<style lang="scss" scoped>
.confirm {
  position: relative;
}
.amount {
  text-align:center;
  width: 30px;
  height: 20px;
  border-radius:20px;
  line-height:20px;
  background-color:red;
  overflow: hidden;
  position: absolute;
  top: -12px;
  right: -12px;
}
</style>
