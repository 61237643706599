<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <el-dialog
      :title="type === 'add' ? '新增' :type === 'edit' ? '修改':'查看'"
      :visible.sync="dialogVisible"
      width="800px"
      center
      :close-on-click-modal="false"
    >
      <el-form
        ref="queryForm"
        label-position="right"
        label-width="100px"
        :model="queryForm"
        :rules="rules"
      >

        <el-row>
          <el-col :span="24">
            <el-form-item label="供应商" prop="vendorId">
              <Select
                v-model="queryForm.vendorId"
                class="full-width"
                api-key="vendorList"
                :disabled="type==='detail'"
                clearable
                @change="changeVendor"
                @responseData="val=>vendorList=val"
              />
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item label="采购主体" prop="cargoOwnerCode">
              <el-select
                v-model="queryForm.cargoOwnerCode"
                class="full-width"
                :disabled="type==='detail'"
                clearable
                filterable
                :placeholder="$t('page.selectPlaceholder')"
              >
                <el-option v-for="(item,index) in cargoList" :key="index" :label="item.cargoOwnerName" :value="item.cargoOwnerCode" />
              </el-select>
            </el-form-item>
          </el-col> -->
          <el-col :span="12">
            <el-form-item label="赔偿类型" prop="compensationTypeDict">
              <el-radio-group v-model="queryForm.compensationTypeDict" :disabled="type==='detail'" @change="handleChangeComType">
                <el-radio v-for="(item,index) in _getAllCommodityDict('COMPENSATION_TYPE')" :key="index" :label="item.val">{{ item.label }}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="业务类型" prop="businessTypeDict">
              <Select
                v-model="queryForm.businessTypeDict"
                :disabled="type==='detail'"
                :select-options="_getAllCommodityDict('COMPENSATION_BUSINESS_TYPE')"
                :configuration="optionsConfig"
                clearable
                class="full-width"
                @change="handleChangeBusType"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="赔偿原因" prop="reasonDict">
              <el-select
                v-model="queryForm.reasonDict"
                class="full-width"
                :disabled="type==='detail'"
                clearable
                filterable
                :placeholder="$t('page.selectPlaceholder')"
              >
                <el-option v-for="(item,index) in _getAllCommodityDict(reasonDictKey)" :key="index" :label="item.label" :value="item.val" />
              </el-select>

            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="金额" prop="amount">
              <el-input-number
                ref="input"
                v-model="queryForm.amount"
                :disabled="type==='detail'"
                :controls="false"
                :precision="2"
                :min="0.01"
                style="width: 100%"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="币种" prop="currencyCode">
              <el-select
                v-model="queryForm.currencyCode"
                class="full-width"
                :disabled="type==='detail'"
                clearable
                filterable
                :placeholder="$t('page.selectPlaceholder')"
              >
                <el-option v-for="(item,index) in accountList" :key="index" :label="item" :value="item" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注" prop="remark">
              <el-input
                v-model="queryForm.remark"
                :disabled="type==='detail'"
                name="season"
                type="textarea"
                :rows="2"
                :maxlength="200"
                show-word-limit
                class="full-width"
                :autosize="{ minRows: 2, maxRows: 4 }"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item ref="detailUpload" prop="detailFilePath" label-width="170px">
              <div slot="label">
                <div>上传赔偿明细文件</div>
                <div style="color:red;font-size:12px;font-weight:400">大小不超过50M</div>
              </div>
              <el-upload
                v-if="type!=='detail'"
                ref="detailUploadRef"
                class="ml-5"
                :headers="uploadHeaders"
                :action="action"
                accept="*"
                :before-upload="beforeUpload"
                :on-remove="removeFile"
                :on-success="handleSuccess"
                :file-list="lfoafilelist"
                :on-change="handleChangeFile"
              >
                <el-button size="small" type="primary" style="margin-top:35px">点击上传</el-button>
              </el-upload>
              <el-button v-if="isDetail" type="text" style="margin-top:35px" class="ml-5" @click="handleDownload(queryForm.detailFilePathUrl)">{{ queryForm.detailFileName }}</el-button>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item ref="upload" :required="true" prop="vendorConfirmPathUrl" label-width="170px">
              <div slot="label">
                <div>上传供应商确认邮件截图</div>
                <div style="color:red;font-size:12px;font-weight:400;width:170px">请上传jpg/png/jpeg格式图片或pdf文件，
                  大小不超过5M</div>
              </div>
              <el-upload
                v-if="!queryForm.showPdf||type === 'add'"
                ref="upload"
                class="ml-5"
                :disabled="type==='detail'"
                :class="{ hide: hideUploadUrl }"
                :headers="uploadHeaders"
                :action="action"
                :show-file-list="false"
                list-type="picture-card"
                :on-success="vendorComfirmUrlHandleSuccess"
                :on-remove="vendorComfirmUrlHandleRemove"
                :on-change="vendorComfirmUrlHandleFileChange"
                :before-upload="beforeAvatarUpload"
              >
                <div v-if="queryForm.vendorConfirmPathUrl">
                  <el-image
                    v-if="!queryForm.vendorConfirmPathUrl.includes('.pdf')"
                    width="100%"
                    height="100%"
                    :src="queryForm.vendorConfirmPathUrl"
                    alt=""
                    :preview-src-list="isDetail?[queryForm.vendorConfirmPathUrl]:[]"
                  />
                  <i v-else class="el-icon-document" />
                </div>
                <i v-else class="el-icon-plus" />
              </el-upload>
              <el-button v-else class="ml-5" style="margin-top:35px" type="text" @click="handleDownload(queryForm.vendorConfirmPathUrl)">{{ queryForm.vendorConfirmName }}</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关闭</el-button>
        <el-button v-if="!isDetail" type="primary" :loading="loading" @click="handleSubmit('save')">保存</el-button>
        <el-button v-if="!isDetail" type="primary" :loading="loading" @click="handleSubmit('submit')">提交</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import { detail, insert, submit, update, currencyByVendor } from '@/api/composition'
import Select from '@/components/Selection'

export default {
  components: { Select },
  mixins: [commodityInfoDict],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number,
      default: 0
    },
    type: {
      type: String,
      default: () => {
        return ''
      }
    }
  },
  data() {
    return {
      fullscreenLoading: false,
      uploadHeaders: {
        authorization: this.$store.getters.authorization
      },
      optionsConfig: { key: 'val', label: 'label', value: 'val' },
      hideUploadUrl: false,
      queryForm: this.initQueryForm(),
      loading: false,
      action: process.env.VUE_APP_SCM_API + 'scm-purchase/basic/uploadFileV2',
      lfoafilelist: [],
      fileList: [],
      rules: {
        vendorId: [{ required: true, message: '必填', trigger: ['change'] }],
        compensationTypeDict: [{ required: true, message: '必填', trigger: ['change'] }],
        businessTypeDict: { required: true, message: '必填', trigger: 'change' },
        reasonDict: [{ required: true, message: '必填', trigger: ['change'] }],
        amount: [{ required: true, message: '必填', trigger: ['blur'] }],
        currencyCode: [{ required: true, message: '必填', trigger: ['change'] }],
        detailFilePath: { required: true, message: '必填', trigger: ['blur', 'change'] },
        vendorConfirmPathUrl: [{ required: true, message: this.$t('page.required'), trigger: ['blur', 'change'] }]
      },
      accountList: [],
      vendorList: [],
      dictList: [
        { key: 'compensationTypeDict', dictKey: 'COMPENSATION_TYPE' },
        { key: 'businessTypeDict', dictKey: 'COMPENSATION_BUSINESS_TYPE' },
        { key: 'reasonDict', dictKey: this.reasonDictKey }
      ],
      files: {}

    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    reasonDictKey() {
      const { compensationTypeDict, businessTypeDict } = this.queryForm
      return `${compensationTypeDict}_${businessTypeDict}`
    },
    isDetail() {
      return this.type === 'detail'
    }

  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        this.$refs.queryForm && this.$refs.queryForm.resetFields()
        this.accountList = []
      } else {
        if (this.type !== 'add') {
        // 查看 修改
          this.handleDetail()
        } else {
          // 新增
          Object.assign(this.queryForm, this.initQueryForm())
          this.$refs.detailUploadRef?.clearFiles()
          this.lfoafilelist = []
          this.fileList = []
        }
        this.$refs.queryForm && this.$refs.queryForm.clearValidate()
      }
    }

  },
  created() { },
  mounted() { },
  methods: {
    initQueryForm() {
      return {
        vendorId: '',
        compensationTypeDict: 'COMPENSATION_CLAIMANT',
        amount: undefined,
        currencyCode: '',
        vendorConfirmPathUrl: '',
        detailFilePath: '',
        businessTypeDict: 'DELIVER',
        reasonDict: '',
        remark: '',
        id: '',
        vendorConfirmName: ''
      }
    },
    handleDownload(url) {
      window.open(url, '_blank')
    },

    handleParams() {
      for (const key in this.queryForm) {
        if (this.dictList.find(item => item.key === key)) {
          const label = key.split('Dict')[0]
          this.queryForm[`${label}Name`] = this.handleDictName(key, this.queryForm[key])
        }
        if (key === 'vendorId') {
          this.queryForm.vendorName = this.vendorList.find(item => item.id === this.queryForm[key])?.vendorName
        }
      }
      return Object.assign(this.queryForm, { vendorConfirmPath: this.files.path })
    },
    handleDictName(key, value) {
      // 根据对象key找字典的key
      const dictList = [
        { key: 'compensationTypeDict', dictKey: 'COMPENSATION_TYPE' },
        { key: 'businessTypeDict', dictKey: 'COMPENSATION_BUSINESS_TYPE' },
        { key: 'reasonDict', dictKey: this.reasonDictKey }
      ]
      const dictKey = dictList.find(item => item.key === key)?.dictKey
      return this._getAllCommodityDict(dictKey).find(item => item.val === value)?.label
    },
    handleChangeComType() {
      this.queryForm.reasonDict = ''
      this.queryForm.businessTypeDict = ''
    },
    handleChangeBusType() {
      this.queryForm.reasonDict = ''
    },
    handleSubmit(flag) {
      this.$refs.queryForm.validate(async(valid, obj) => {
        if (valid) {
          try {
            this.loading = true
            if (flag === 'save') {
              if (this.type === 'add') {
                await insert(this.handleParams())
              }
              if (this.type === 'edit') {
                await update(this.handleParams())
              }
              this.$emit('input', false)
              this.$emit('update')
            } else {
              this.$confirm('提交后将发起审批流程，是否确认', this.$t('page.Prompt'), {
                confirmButtonText: this.$t('title.confirm'),
                cancelButtonText: this.$t('title.cancel'),
                type: 'warning'
              }).then(async() => {
                this.fullscreenLoading = true
                await submit(this.handleParams())
                this.fullscreenLoading = false
                this.$emit('input', false)
                this.$emit('update')
              }).catch(() => {
                this.fullscreenLoading = false
              })
            }
          } finally {
            this.loading = false
            this.fullscreenLoading = false
          }
        } else {
          return false
        }
      })
    },
    changeVendor(e) {
      this.handleAccount(e)
    },
    async handleAccount(id) {
      const { datas } = await currencyByVendor({ vendorId: id })
      this.accountList = datas
      // const res = new Map()
      // this.accountList = datas.filter((a) => !res.has(a['currencyType']) && res.set(a['currencyType'], 1))
      this.queryForm.currencyCode = datas[0]
    },

    async handleDetail(id) {
      this.lfoafilelist = []
      const { datas, datas: { detailFilePath, vendorConfirmPath, detailFileName: name, vendorConfirmName }} = await detail({ id: this.id })
      if (this.isDetail && vendorConfirmName) {
        const isPdf = vendorConfirmName.split('.').pop() === 'pdf'
        isPdf ? this.queryForm.showPdf = true : this.queryForm.showPdf = false
      } else {
        this.queryForm.showPdf = false
      }
      this.lfoafilelist.push({ name, path: detailFilePath })
      this.files.path = vendorConfirmPath
      Object.assign(this.queryForm, datas, { remark: datas.remark || '' })
    },
    vendorComfirmUrlHandleSuccess(files, filelist) {
      this.files = files.datas
      this.queryForm.vendorConfirmPathUrl = files.datas.url
      this.$refs.upload && this.$refs.upload.clearValidate()
    },

    vendorComfirmUrlHandleRemove(file, fileList) {
      this.hideUploadUrl = fileList && fileList.length >= 1
      this.queryForm.vendorConfirmPathUrl = ''
    },
    // 文件状态改变时的钩子，将加号隐藏
    vendorComfirmUrlHandleFileChange(file, fileList) {
      this.hideUploadUrl = fileList && fileList.length >= 1
    },
    handleSuccess(files, fileList) {
      this.lfoafilelist = fileList
      this.queryForm.detailFilePath = files.datas.path
      this.$refs.detailUpload && this.$refs.detailUpload.clearValidate()
    },
    handleChangeFile(file, fileList) {
      if (fileList.length > 1) {
        fileList.splice(0, 1)
      }
      this.lfoafilelist = fileList
    },
    removeFile(val) {
      this.queryForm.detailFilePath = ''
    },
    beforeUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 50
      if (!isLt5M) { this.$message.error('上传图片或文件大小不能超过 50MB!') }
      return isLt5M
    },
    beforeAvatarUpload(file) {
      const name = file.name.split('.')
      const isLt5M = file.size / 1024 / 1024 < 5
      const types = ['png', 'jpg', 'jpeg', 'pdf']
      const isApk = types.includes(name[name.length - 1])
      if (!isApk || !isLt5M) {
        this.$message({
          dangerouslyUseHTMLString: true,
          type: 'error',
          message: '请上传jpg/png/jpeg格式图片或pdf文件，大小不超过5M'
        })
      }
      return isApk && isLt5M
    }

  }
}
</script>
<style lang="scss" scoped>
.full-width {
  width: 100%;
}
</style>
