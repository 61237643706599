<template>
  <div>
    <el-dialog
      title="品质赔偿单确认"
      :visible.sync="dialogVisible"
      width="90%"
      center
      :close-on-click-modal="false"
    >
      <QueryForm
        ref="queryForm"
        v-model="queryForm"
        :query-btn-loading="tableLoading"
        @query="handleQuery"
        @reset="handleQueryFormReset"
      >
        <el-form-item label="赔偿单号">
          <el-input
            v-model="queryForm.compensationNo"
          />
        </el-form-item>
        <el-form-item label="状态">
          <Select
            v-model="queryForm.stateList"
            :select-options="dictOptions"
            clearable
            :configuration="optionsConfig"
          />
        </el-form-item>
        <el-form-item label="供应商">
          <Select
            v-model="queryForm.vendorIdList"
            api-key="vendorList"
            clearable
            multiple
          />
        </el-form-item>
        <el-form-item label="币种">
          <Select
            v-model="queryForm.currencyCodeList"
            :select-options="_getAllCommodityDict('CURRENCY_TYPE')"
            :configuration="optionsConfig"
            clearable
            multiple
          />
        </el-form-item>
        <el-form-item label="邮件发送状态">
          <Select
            v-model="queryForm.emailState"
            :select-options="_getAllCommodityDict('COMPENSATION_EMAIL_STATE')"
            :configuration="optionsConfig"
            clearable
            multiple
          />
        </el-form-item>
        <el-form-item label="创建人">
          <Select
            v-model="queryForm.createById"
            api-key="qmsUserList"
            clearable
          />
        </el-form-item>
        <el-form-item label="创建时间" :class="$i18n.locale">
          <el-date-picker
            v-model="queryForm.value1"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期"
            :default-time="['00:00:00','23:59:59']"
            type="datetimerange"
          />
        </el-form-item>
      </QueryForm>
      <div style="margin-bottom: 5px;"><el-button type="primary" :loading="loading" @click="handleEmail()">发送邮件</el-button></div>
      <el-form ref="ruleForm" :model="ruleForm" :rules="ruleForm.rules">
        <Table
          :table-data="ruleForm.tableData"
          max-height="450px"
          :columns="confirmComponentColumns"
          :check="true"
          :loading="tableLoading"
          border
          @select="select"
          @selectAll="selectAll"
          @handleSelectionChange="handleSelectionChange"
        >
          <el-table-column slot="confirmAmount" width="140" label="确认金额">
            <template slot-scope="scope">
              <el-form-item
                :prop="'tableData.' + scope.$index + '.amount'"
                :rules="[{ required: scope.row.setRule || false, message: '必填' }]"
                :validate-event="false"
              >
                <el-input-number
                  v-model.number="scope.row.amount"
                  class="full-width"
                  :min="0.01"
                  :disabled="scope.row.state!=='6'"
                  :controls="false"
                  :precision="2"
                  :validate-event="false"
                />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column slot="remark" width="170" label="备注">
            <template slot-scope="scope">
              <el-form-item
                :prop="'tableData.' + scope.$index + '.remark'"
                :rules="[{ required: scope.row.setRule &&(scope.row.amount!==scope.row.originAmount),
                           message: '必填' }]"
              >
                <el-input
                  v-model="scope.row.remark"
                  type="textarea"
                  :autosize="{ minRows: 3}"
                  :maxlength="200"
                  :disabled="scope.row.state!=='6'"
                  show-word-limit
                  :validate-event="false"
                />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column slot="attachmentfile" width="120" label="赔偿明细附件">
            <template slot-scope="scope">
              <el-button v-if="scope.row.detailFilePathUrl" type="text" @click="handleDownload(scope.row.detailFilePathUrl)">下载</el-button>
            </template>
          </el-table-column>
          <el-table-column slot="emailState" width="120" label="邮件发送状态">
            <template slot-scope="scope">{{ scope.row.emailState ==='1'?'已发送':'未发送' }}</template>
          </el-table-column>
          <el-table-column slot="vendorImg" label="供应商确认邮件截图" align="center" width="150">
            <template slot-scope="scope">
              <el-form-item
                ref="imgRef"
                :prop="'tableData.' + scope.$index + '.vendorConfirmPathUrl'"
                :rules="[{ required: scope.row.setRule|| false , message: '必填', trigger: 'change' }]"
              >
                <el-upload
                  class="avatar-uploader"
                  :show-file-list="false"
                  :headers="uploadHeaders"
                  :http-request="()=>{uploadFile(scope.row)}"
                  action=""
                  :disabled="scope.row.state!=='6'"
                  :on-change="vendorComfirmUrlHandleFileChange"
                  :before-upload="beforeAvatarUpload"
                >
                  <img v-if="scope.row.vendorConfirmPathUrl" :src="scope.row.vendorConfirmPathUrl" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon" />
                </el-upload>
              </el-form-item>
            </template>
          </el-table-column>
        </Table>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关闭</el-button>
        <el-button type="primary" @click="handleReject">驳回</el-button>
        <el-button type="primary" @click="handlePass">通过</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="驳回"
      :visible.sync="rejectDialogVisible"
      width="400px"
      center
    >
      <span>该操作无法撤销，请确认！</span>
      <el-form ref="validForm" :model="validForm" label-width="80px" class="mt-3">
        <el-form-item label="驳回原因" prop="rejectionReason" :rules="{required: true, message: '必填',trigger:'blur'}">
          <el-input
            v-model="validForm.rejectionReason"
            type="textarea"
            :rows="2"
            :maxlength="200"
            show-word-limit
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="rejectDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="rejectSure">确 定</el-button>
      </span>
    </el-dialog>
    <SendEmail v-model="sendEmailVisible" :title="'包装罚款邮件'" :detail-datas="detailDatas" @handleSubmit="handleSubmit" />
  </div>

</template>

<script>
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import Select from '@/components/Selection'
import QueryForm from '@/components/QueryForm'
import { omit } from 'lodash'
import Table from '@/components/Table'
import SendEmail from '@/components/SendEmail'
import { confirmComponentColumns } from '@/constant/tablecolumns'
import { pageQms, updateQms, uploadByPreUrlApi, sendEmailValid, emailDetailApi, sendEmailApi } from '@/api/composition'
import axios from 'axios'
import { qmsDictList } from '@/api/listSelection'

export default {
  components: { Select, QueryForm, Table, SendEmail },
  mixins: [commodityInfoDict],
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      reservedList: [],
      file: '',
      fileForm: {},
      disabled: true,
      rejectDialogVisible: false,
      tableLoading: false,
      confirmComponentColumns,
      multipleTable: [],
      queryForm: { value1: [], stateList: '6' },
      ruleForm: { tableData: [], rules: {}},
      optionsConfig: { key: 'val', label: 'label', value: 'val' },
      loading: false,
      rules: {},
      validForm: { rejectionReason: '' },
      dictOptions: [],
      localKey: 'reservedList',
      sendEmailVisible: false,
      detailDatas: {}
    }
  },
  computed: {
    uploadHeaders() {
      return { authorization: this.$store.getters.authorization }
    },
    dialogVisible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    queryParams() {
      const { value1 } = this.queryForm
      const [startCreateTime, endCreateTime] = value1 || []
      return Object.assign({},
        omit(this.queryForm, ['value1']),
        { startCreateTime, endCreateTime, current: 1, size: 200 }
      )
    },
    rejectParams() {
      return Object.assign({ dto: this.multipleTable }, this.validForm, { state: 7 })
    },
    successParams() {
      return Object.assign({ dto: this.multipleTable }, { state: 2 })
    }
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.handleQuery()
      } else {
        this.queryForm = this.$options.data.call(this).queryForm
        this.$emit('update')
      }
      this.$refs.ruleForm?.clearValidate()
    },
    rejectDialogVisible(val) {
      if (val) {
         this.$refs.validForm?.clearValidate()
        this.$refs.validForm?.resetFields()
      }
    }

  },
  created() { },
  async mounted() {
    const { datas } = await qmsDictList({ type: 'COMPENSATION_STATE' })
    const LangObj = {
      'zh': 'zh-CN',
      'en': 'en-US'
    }
    const langKey = LangObj[this.$i18n.locale]
    const Arr = []
    datas.COMPENSATION_STATE.map(v => {
      if (v.dictName) Arr.push({ label: JSON.parse(v.dictName)[langKey], val: v.dictValue })
    })
    this.dictOptions = Arr
  },
  methods: {
    handleSuccess(row) {
      // 取缓存保留上传图片
      this.reservedList = JSON.parse(sessionStorage.getItem(this.localKey)) || []
      const obj = this.reservedList.find(item => item.id === row.id)
      if (obj) {
        obj.vendorConfirmPathUrl = row.vendorConfirmPathUrl
        obj.vendorConfirmPath = row.vendorConfirmvendorConfirmPathPathUrl
      } else {
        this.reservedList.push(row)
      }
      // 保存到缓存
      sessionStorage.setItem(this.localKey, JSON.stringify(this.reservedList))
    },
    beforeAvatarUpload(file) {
      const name = file.name.split('.')
      const isLt5M = file.size / 1024 / 1024 < 5
      const types = ['png', 'jpg', 'jpeg']
      const isApk = types.includes(name[name.length - 1])
      if (!isApk || !isLt5M) {
        this.$message({
          dangerouslyUseHTMLString: true,
          type: 'error',
          message: '请上传jpg/png/jpeg格式图片，大小不超过5M'
        })
      }
      return isApk && isLt5M
    },
    vendorComfirmUrlHandleFileChange(uploadFile, fileList,) {
      this.file = uploadFile.raw
      if (fileList.length > 1) {
        fileList.splice(0, 1)
      }
      const { name: fileName, size: kbSize } = uploadFile
      Object.assign(this.fileForm, {
        fileName,
        kbSize: Math.floor(kbSize / 1024),
        fileType: fileName.substring(fileName.lastIndexOf('.') + 1),
        configCode: '0200100102'
      })
    },
    async  uploadFile(row) {
      const { datas } = await uploadByPreUrlApi(this.fileForm)
      const {
        originName: name,
        objectName: key,
        policy,
        accessid: OSSAccessKeyId,
        success_action_status = 200,
        callback,
        signature,
        host: url
      } = datas
      const form = Object.assign(
        {},
        { name, key, policy, OSSAccessKeyId, success_action_status, callback, signature },
        { file: this.file }
      )

      const formData = new FormData()
      Object.keys(form).forEach((key) => formData.set([key], form[key]))
      const { data } = await axios({
        method: 'post',
        url,
        data: formData
      })

      this.$set(row, 'vendorConfirmPathUrl', data?.datas?.url)
      this.$set(row, 'vendorConfirmPath', key)
      this.handleSuccess(row)
    },
    handleDownload(url) {
      window.open(url, '_blank')
    },
    handleReject() {
      if (!this.multipleTable.length || this.multipleTable.some(item => item.state + '' !== '6')) {
        return this.$message.warning('至少选中一笔数据，仅状态为“采购确认中”的赔偿单可操作')
      }
      // 清空所有校验
      this.$refs.ruleForm.clearValidate()
      this.rejectDialogVisible = true
    },
    async rejectSure() {
      this.$refs.validForm.validate(async valid => {
        if (valid) {
          const { code, msg } = await updateQms(this.rejectParams)
          if (code === 0) {
            this.$notify({
              message: msg,
              type: 'success'
            })
            this.rejectDialogVisible = false
            this.handleQuery()
          }
        }
      })
    },
    handlePass() {
      if (!this.multipleTable.length || this.multipleTable.some(item => item.state + '' !== '6')) {
        return this.$message.warning('至少选中一笔数据，仅状态为“采购确认中”的赔偿单可操作')
      }
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          this.$confirm('确认通过后将发起审批流程，该操作无法撤销，请确认所选赔偿单金额无误?', '提示', {
            confirmButtonText: '确定',
            showCancelButton: false,
            type: 'warning'
          }).then(async() => {
            await updateQms(this.successParams)
            this.$notify({
              title: '操作成功',
              message: '操作成功',
              type: 'success'
            })
            this.handleQuery()
          })
        } else {
          this.$message.warning('请校验列表已勾选数据必填字段')
        }
      })
    },
    handleQueryFormReset() {
      this.queryForm = this.$options.data().queryForm
      this.handleQuery()
    },
    handleSelectionChange(val) {
      this.multipleTable = val
    },
    selectAll(val) {
      if (!Array.isArray(val)) return
      val.length ? val.map(v => this.$set(v, 'setRule', true)) : this.ruleForm.tableData.map(v => this.$set(v, 'setRule', false))
    },
    select({ rows, row }) {
      const selected = rows.length && rows.indexOf(row) !== -1
      this.$set(row, 'setRule', selected)
    },
    async handleQuery() {
      try {
        this.tableLoading = true
        const { code, datas: { records = [] }} = await pageQms(this.queryParams)
        if (code === 0) {
          this.ruleForm.tableData = records && records.length && records.map(item => {
            return { ...item,
              originAmount: item.state + '' === '6' ? item.amount : item.originAmount }
          }) || []
          // 读取原来存的图片
          this.reservedList = JSON.parse(sessionStorage.getItem(this.localKey)) || []
          this.ruleForm.tableData.map(item => {
            this.reservedList.map(e => {
              if (item.id === e.id) {
                this.$set(item, 'vendorConfirmPathUrl', e.vendorConfirmPathUrl)
                this.$set(item, 'vendorConfirmPath', e.vendorConfirmPath)
              }
            })
          })
        }
      } finally {
        this.tableLoading = false
      }
    },
    // 发送邮件
    async handleEmail() {
      if (!this.multipleTable.length) return this.$message.warning('至少选择一条数据')
      if (this.multipleTable.some(item => item.vendorId !== this.multipleTable[0].vendorId)) return this.$message.warning('请勾选统一供应商')
      if (this.multipleTable.some(item => item.reasonDict !== 'CLAIMANT_QC_2')) return this.$message.warning('请勾选赔偿原因为包装异常的数据')
      if (this.multipleTable.some(item => item.compensationTypeDict !== 'COMPENSATION_CLAIMANT')) return this.$message.warning('请勾选赔偿类型为【索赔】的数据')
      if (this.multipleTable.some(item => item.state !== '6')) return this.$message.warning('请勾选采购确认中的数据进行操作')
      // 请勾选生成时间为统一月份的邮件
      try {
        this.loading = true
        const { datas: poDatas } = await sendEmailValid(this.multipleTable)
        // 获取邮件详情
        const { datas: emailInfo } = await emailDetailApi(poDatas)
        const receiverEmail = JSON.parse(emailInfo.receiverEmail)?.join('；')
        const ccEmail = JSON.parse(emailInfo.ccEmail)?.join('；')
        Object.assign(emailInfo, { receiverEmail, ccEmail, attachments: emailInfo?.compensationDetailAttachment ? [emailInfo?.compensationDetailAttachment] : [] })
        this.detailDatas = emailInfo
        this.sendEmailVisible = true
      } finally {
        this.loading = false
      }
    },
    async handleSubmit(val) {
      const { receiverEmail, ccEmail, bccEmail } = val
      const params = {}
      Object.assign(params, val, { receiverEmail: JSON.stringify(receiverEmail?.split('；')), ccEmail: JSON.stringify(ccEmail?.split('；')), bccEmail: JSON.stringify(bccEmail?.split('；')) })
      await sendEmailApi(params)
      this.handleQuery()
      this.sendEmailVisible = false
    }

  }
}
</script>
<style lang="scss" scoped>
.full-width {
  width: 100%;
}
 /deep/ .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9!important;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 90px;
    height: 90px;
    line-height: 90px;
    text-align: center;
  }
  .avatar {
    width: 90px;
    height: 90px;
    display: block;
  }
</style>
