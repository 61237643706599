import request from '@/utils/scm-request'

export function page(params) {
  return request({
    url: `scm-purchase/compensation/page`,
    method: 'get',
    params
  })
}

export function invalid(data) {
  return request({
    url: `scm-purchase/compensation/invalid`,
    method: 'post',
    data
  })
}

export function detail(params) {
  return request({
    url: `scm-purchase/compensation/detail`,
    method: 'get',
    params
  })
}

export function cargoByVendor(params) {
  return request({
    url: `scm-purchase/basic/cargoVendor`,
    method: 'get',
    params
  })
}

export function insert(data) {
  return request({
    url: `scm-purchase/compensation/insert`,
    method: 'post',
    data
  })
}

export function submit(data) {
  return request({
    url: `scm-purchase/compensation/submit`,
    method: 'post',
    data
  })
}

export function exportfile(params) {
  return request({
    url: `scm-purchase/compensation/export`,
    method: 'get',
    params,
    responseType: 'arraybuffer'
  })
}

export function update(data) {
  return request({
    url: `scm-purchase/compensation/update`,
    method: 'post',
    data
  })
}

export function currencyByVendor(params) {
  return request({
    url: `scm-purchase/basic/vendorOfferCurrency`,
    method: 'get',
    params
  })
}

export function pageQms(params) {
  return request({
    url: `scm-purchase/compensation/pageQms`,
    method: 'get',
    params
  })
}

export function countQms(params) {
  return request({
    url: `scm-purchase/compensation/countQms`,
    method: 'get',
    params
  })
}

export function updateQms(data) {
  return request({
    url: `scm-purchase/compensation/updateQms`,
    method: 'post',
    data
  })
}

export function uploadByPreUrlApi(data) {
  return request({
    url: `scm-purchase/basic/qms/uploadByPreUrl`,
    method: 'post',
    data
  })
}

// 品质赔偿单确认-邮件校验
export function sendEmailValid(data) {
  return request({
    url: `scm-purchase/compensation/sendEmailValid`,
    method: 'post',
    data
  })
}

// 邮件详情
export function emailDetailApi(data) {
  return request({
    url: `scm-purchase/compensation/emailDetail`,
    method: 'post',
    data
  })
}

// 发送邮件
export function sendEmailApi(data) {
  return request({
    url: `scm-purchase/compensation/sendEmail`,
    method: 'post',
    data
  })
}
